import React, { useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import './Navigation.css';

const Navigation = (props) => {
  const [menuToggle, setMenuToggle] = useState(false);
  let { pathname } = useLocation();
  function toggleMenuHandler() {
    setMenuToggle((curState) => !curState);
  }
  const hideShowClass = (menuToggle) ? "collapse navbar-collapse show" : "collapse navbar-collapse";
  return (
    <div>
      <nav className="navbar navbar-expand-lg  navbar-dark  nav ">
        <button className="navbar-toggler navbar-toggler-right mr-auto" type="button" onClick={toggleMenuHandler}>
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={hideShowClass} onClick={toggleMenuHandler}>
          <ul className="navbar-nav mx-auto ">
            <li className="nav-item mr-5">
              <Link className={`nav-link ${pathname === '/' ? 'active' : ''}`} to="/">Home</Link>
            </li>
            <li className="nav-item mr-5">
              <Link className={`nav-link ${pathname === '/today-result' ? 'active' : ''}`} to="/today-result">Today Results</Link>
            </li>
            <li className="nav-item mr-5">
              <Link className={`nav-link ${pathname === '/old-result' ? 'active' : ''}`} to="/old-result">Old Results</Link>
            </li>
            <li className="nav-item mr-5">
              <Link className={`nav-link ${pathname === '/live' ? 'active' : ''}`} to="/live">Live Draw</Link>
            </li>
            {/* <li className="nav-item mr-5">
              <Link className={`nav-link ${pathname === '/verify-ticket' ? 'active' : ''}`} to="/verify-ticket">Verify Ticket</Link>
            </li> */}
            <li className="nav-item mr-5">
              <Link className={`nav-link ${pathname === '/claims' ? 'active' : ''}`} to="/claims">Claims</Link>
            </li>
            <li className="nav-item mr-5">
              <Link className={`nav-link ${pathname === '/act-rules' ? 'active' : ''}`} to="/act-rules">Act & Rules</Link>
            </li>
            <li className="nav-item mr-5">
              <Link className={`nav-link ${pathname === '/news-updates' ? 'active' : ''}`} to="/news-updates">News & Updates</Link>
            </li>
            <li className="nav-item mr-5">
              <Link className={`nav-link ${pathname === '/contact' ? 'active' : ''}`} to="/contact">Contact Us</Link>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navigation;

